<template>
    <div>
      <div class="page-container block-f">
        <div class="text-h5 text-center font-weight-bold mb-10 pb-5">{{ $t('PackageA.name') }}</div>
        <!-- <div class="text-h5 text-center font-weight-bold mb-15 pb-5">{{ $t('Research.Title') }}</div> -->
        <v-row class="d-flex justify-space-around align-center">
            <v-col sm="6" md="6" lg="4">
                <v-card :height="newsil8n=='en'? 680 : 550" class="py-6" style="background-color:rgb(251, 249, 246);">
                    <span></span>
                    <div class="package-tip d-flex justify-center align-center ">
                        <div class="text-subtitle-1 font-weight-bold text-center">{{ $t('PackageA.package') }}A</div>
                    </div>
                    <div class="px-6 mt-6">
                        <div class="d-flex justify-space-between">
                            <div :style="(newsil8n=='en'?'height:60px;':'height:60px;')+'max-width=50%'" class="text-body-2 font-weight-bold">{{ $t('PackageA.planA.name') }}</div>
                            <div class="text-color text-body-2 font-weight-medium text-center">{{ $t('PackageA.planA.level') }}</div>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-center align-center">
                            <img width="60px" src="../../assets/images/usdt.png" alt="usdt">
                            <div class="text-h5 font-weight-bold text-center pl-4">{{ $t('PackageA.frequency') }}</div>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium mt-4">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con1') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 150 {{ $t('PackageA.company') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 15 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con2') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 30 {{ $t('PackageA.company') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 0.3 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con3') }}</div>
                            </div>
                            <div class="text-color text-body-2 font-weight-medium text-center">150ml</div>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col sm="6" md="6" lg="4">
                <v-card :height="newsil8n=='en'? 680 : 550" class="py-6" style="background-color:rgb(251, 249, 246);">
                    <span></span>
                    <div class="package-tip d-flex justify-center align-center ">
                        <div class="text-subtitle-1 font-weight-bold text-center">{{ $t('PackageA.package') }}B</div>
                    </div>
                    <div class="px-6 mt-6">
                        <div class="d-flex justify-space-between">
                            <div :style="(newsil8n=='en'?'height:60px;':'height:60px;')+'max-width=50%'" class="text-body-2 font-weight-bold">{{ $t('PackageA.planB.name') }}</div>
                            <div class="text-color text-body-2 font-weight-medium text-center">{{ $t('PackageA.planB.level') }}</div>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-center align-center">
                            <img width="60px" src="../../assets/images/usdt.png" alt="usdt">
                            <div class="text-h5 font-weight-bold text-center pl-4">{{ $t('PackageA.frequency') }}</div>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium mt-4">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con1') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 250 {{ $t('PackageA.company') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 25 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con2') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 30-50 {{ $t('PackageA.company') }} </div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 0.3-0.5 {{ $t('PackageA.company') }} </div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con4') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 30 {{ $t('PackageA.company') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 0.3 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold ">{{ $t('PackageA.con3') }}</div>
                            </div>
                            <div class="text-color text-body-2 font-weight-medium">150ml</div>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col sm="6" md="6" lg="4">
                <v-card :height="newsil8n=='en'? 680 : 550" class="py-6" style="background-color:rgb(251, 249, 246);">
                    <span></span>
                    <div class="package-tip d-flex justify-center align-center ">
                        <div class="text-subtitle-1 font-weight-bold text-center">{{ $t('PackageA.package') }}C</div>
                    </div>
                    <div class="px-6 mt-6">
                        <div class="d-flex justify-space-between">
                            <div :style="(newsil8n=='en'?'height:60px;':'height:60px;')+'max-width=50%'" class="text-body-2 font-weight-bold">{{ $t('PackageA.planC.name') }}</div>
                            <div class="text-color text-body-2 font-weight-medium text-center">{{ $t('PackageA.planC.level') }}</div>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-center align-center">
                            <img width="60px" src="../../assets/images/usdt.png" alt="usdt">
                            <div class="text-h5 font-weight-bold text-center pl-4">{{ $t('PackageA.frequency') }}</div>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-6">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy1') }}</div>
                            <v-btn href="https://dapp.supercells.pro/swap" depressed height="24" color="red" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount1') }}</v-btn>
                        </div>
                        <div v-if="newsil8n=='en'" class="d-flex flex-column justify-center align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium mt-4">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2 mt-4">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <div v-else class="d-flex justify-space-between align-center mt-1">
                            <div class="grey--text text-subtitle-1 font-weight-medium">{{ $t('PackageA.buy2') }}</div>
                            <v-btn  href="https://dapp.supercells.pro/swap" depressed height="24" color="#A09079" class="white--text font-weight-regular pa-2">{{ $t('PackageA.discount2') }}</v-btn>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con1') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 2 {{ $t('PackageA.company3') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 20 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con2') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium"> {{ $t('PackageA.company2') }} 30-50 {{ $t('PackageA.company') }} </div>
                            <div v-else class="text-color text-body-2 font-weight-medium"> {{ $t('PackageA.company2') }} 0.3-0.5 {{ $t('PackageA.company') }} </div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con5') }}</div>
                            </div>
                            <div v-if="newsil8n=='en'" class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 30 {{ $t('PackageA.company') }}</div>
                            <div v-else class="text-color text-body-2 font-weight-medium">{{ $t('PackageA.company2') }} 0.3 {{ $t('PackageA.company') }}</div>
                        </div>
                        <div class="d-flex justify-space-between align-center mt-1">
                            <div class="d-flex align-center">
                                <div class="point"></div>
                                <div class="text-body-2 font-weight-bold">{{ $t('PackageA.con3') }}</div>
                            </div>
                            <div class="text-color text-body-2 font-weight-medium">150ml</div>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue'


export default {
  components: {
    Footer
  },
  data:() => ({
    newsil8n:null,
    }),
  mounted(){
    this.newsil8n = this.$i18n.locale
  },
  watch:{
        '$i18n.locale'(newil8n){
        console.log(newil8n);
        this.newsil8n=newil8n;
        }
    },
}
</script>

<style>
.v-card > div{
    border-bottom-left-radius: none !important;
    border-bottom-right-radius: none !important;
}

  .page-container {
    min-height: calc(100vh - 289px);
    padding: 120px;
    background: #F8F4F0;
  }
  .package-tip{
    border-bottom-left-radius: none !important;
    border-bottom-right-radius: none !important;
    width: 150px;
    height: 40px;
    background: rgb(160, 144, 121);
    position: relative;
    color: #333;
  }
  .package-tip::after{
    position: absolute;
    content: "";
    width: 0;
    height: 40px;
    top: 0;
    right: -40px;
    border-width: 20px;
    border-style: solid;
    border-color:  transparent  transparent transparent rgb(160, 144, 121);
  }
  .text-color{
    color: #AC8250 !important;
  }
  .point{
    width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color:  transparent transparent transparent rgb(160, 144, 121);
  }

  @media screen and (max-width: 960px) {
    .about {
      padding: 30px 24px;
    }
    .page-container {
        min-height: calc(100vh - 266px);
        padding: 80px 24px 30px;
    }
  }
</style>
  